import { useRef, useState } from "react";
import { PilarContext } from "./PilarContext";


export default function PilarProvider({ children }) {  
    
    const [recognition, setRecognition] = useState(null);

    const videoRef = useRef(null);
    const [showVideo, setShowVideo] = useState(false);
    const [playing, setPlaying] = useState(false)

    const handleEnded = () => {
        setShowVideo(false);
        setPlaying(false)
    };

    const playVideo = () => {
        if(!playing) 
        {
            videoRef.current.play();
            setPlaying(true);
        }
    }



  return (
    <PilarContext.Provider value={{ videoRef, showVideo, setShowVideo, handleEnded, playing, playVideo, recognition, setRecognition }}>
        { children }
    </PilarContext.Provider>
  )
}
