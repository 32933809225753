

import { Image } from 'primereact/image';
import { TabView, TabPanel } from 'primereact/tabview';
import { useContext } from 'react';
import { PilarContext } from '../context/PilarContext';
import { Video } from './Video';
import Recognition from './Recognition';
import SpeechToText from './SpeechToText';



export const Main = () => {

    const { showVideo } =  useContext( PilarContext );

  return (
    <main className="main">
        <Image src="/img/logo.png" alt="La Marina" width="500"/>
        { showVideo && <Video /> } 
        <TabView className="witenTabview">
            <TabPanel header="Recognition">
              <Recognition />
            </TabPanel>
            <TabPanel header="Speech to Text Google Cloud">
              <SpeechToText />
            </TabPanel>
        </TabView>
    </main>
  )
}
