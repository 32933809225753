import useSpeechToText from 'react-hook-speech-to-text';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useContext, useEffect } from 'react';
import { PilarContext } from '../context/PilarContext';


const SpeechToText = () => {

    const { setShowVideo, showVideo, recognition } =  useContext( PilarContext );
    useEffect(() => {
     
    
      return () => {
        if (recognition) {
            recognition.stop();
            
          }
      }
    }, [])
    

  const {
    error,
    interimResult,
    isRecording,
    results,
    setResults,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: "AIzaSyBnUCQ_Zvduye8O7IikYXxKJlJqmbSiI5A",
    useLegacyResults: false
  });

 

  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;


  const regex = /\bPilar\b(?!(?:\s+\w+){0,4}\s+marina)/gi;
  if (regex.test(interimResult) && !showVideo) 
      { 
        setTimeout(() => {
          setShowVideo(true);
        }, 1000);
      }

  function handleButton() 
  {
     if(isRecording) {
        stopSpeechToText();
        //setResults([]);
     } else {
        startSpeechToText();
     }
  }

  

  return (
    <div className="card">
      <Button label={isRecording ? 'Detener' : 'Iniciar'} severity={isRecording ? 'danger' : 'success'} onClick={handleButton}/>
      <br/><br/>
      <Card title="Transcripción: ">
        <ul>
            {
                results.map((result) => {
                    //console.log(result);
                    return <li key={result.timestamp}>{result.transcript}</li>;
                })
            }
            {
                interimResult && <li>{interimResult}</li>
            }
        </ul>
      </Card>
    </div>
  );
}

export default SpeechToText;