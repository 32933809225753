import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { PilarContext } from '../context/PilarContext';

const Recognition = () => {
  const [recording, setRecording] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [transcript, setTranscript] = useState('');

  const { setShowVideo, showVideo, recognition, setRecognition } =  useContext( PilarContext );



  const startRecording = () => {
    const newRecognition = new window.webkitSpeechRecognition();
    newRecognition.continuous = true;
    newRecognition.interimResults = true;
    newRecognition.lang = 'es-MX';

    /*navigator.mediaDevices.getUserMedia({ audio: true })
    .then(function(stream) {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = handleData;
      mediaRecorder.start();
    })
    .catch(function(err) {
      console.log('Error al acceder al micrófono:', err);
    });*/

    newRecognition.onstart = () => {
      setRecording(true);
      setTranscript('');
    };

    newRecognition.onstop = () => {
      setRecording(false);
    };

    newRecognition.onresult = (event) => {
      const { results } = event;

      const lastResult = results[results.length - 1];
      const lasTranscript = lastResult[0].transcript;

      const regex = /\bPilar\b(?!(?:\s+\w+){0,4}\s+marina)/gi;
      //console.log(`El show es: ${showVideo}`);
      //console.log(`El ultimo texto es: ${lasTranscript}`);
      if (regex.test(lasTranscript) && !showVideo) 
      { 
        setTimeout(() => {
          setShowVideo(true);
        }, 1000);
      }
      const transcript = Array.from(results)
        .map((result) => {
          
            return result[0];
        })
        .map((result) => {
            const resultTranscript = result.transcript;
            
            return resultTranscript;
        })
        .join('');
      setTranscript(transcript);
    };

    setRecognition(newRecognition);
    newRecognition.start();
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      
    }
  };

  const handleData = (event) => {
    setChunks(prevChunks => [...prevChunks, event.data]);
  };

  const handleRecording = () => {
    if (!recording) {
      setChunks([]);
      startRecording();
    } else {
      stopRecording();
      setRecording(false)
    }
  };

  return (
    <div className="card">
       <Button label={recording ? 'Detener' : 'Iniciar'} severity={recording ? 'danger' : 'success'} onClick={handleRecording}/>
       <br/><br/>
       <Card title="Transcripción: ">
            {transcript && <p className="m-0">{transcript}</p>}
      </Card>
    </div>
  );
};
//const MemoRecognition = React.memo(Recognition);
export default Recognition;