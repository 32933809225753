import { useContext, useEffect } from "react"
import { PilarContext } from "../context/PilarContext"

export const Video = () => {
    
    const { videoRef, handleEnded, playVideo } =  useContext( PilarContext );

    useEffect(() => {
        playVideo();
    }, [playVideo]);

  return (
    <video ref={videoRef}  width={150} className="video-container" onEnded={handleEnded} >
        <source src="/video/pilar.mp4" type="video/mp4"/>
    </video>
  )
}
